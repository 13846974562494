<template>
  <div>
    <div class="d-flex flex-column mb-12">
      <div class="title">{{ $t("what_was_assessed") }}</div>
    </div>

    <div class="d-flex flex-column justify-space-between">
      <template
        v-for="(classification, index) in selectedRecruter.classification"
      >
        <v-row :key="index">
          <v-col cols="12" md="10">
            <div class="d-flex flex-row justify-space-between">
              <div style="width: 80%">
                {{ index + 1 }}. {{ classification.designation }}
              </div>
              <div class="primary--text text-right" style="width: 20%">
                {{ classification.percentageWeight }}%
              </div>
            </div>
            <div class="ml-5 my-2">
              <template
                v-for="(
                  evaluationPoint, pointIndex
                ) in classification.evaluationPoints"
              >
                <div
                  class="d-flex flex-row justify-space-between"
                  :key="`${evaluationPoint.name}`"
                >
                  <div class="text--secondary" style="width: 80%">
                    {{ index + 1 }}.{{ pointIndex + 1 }}.
                    {{ evaluationPoint.name }}
                    <span class="primary--text"
                      >{{ evaluationPoint.percentageWeight }}%</span
                    >
                  </div>
                </div>
                <div class="mt-5" :key="`${evaluationPoint.name}`">
                  <v-slider
                    v-model="evaluationPoint.scoring"
                    :label="$t('level_of_satisfaction')"
                    step="1"
                    thumb-label="always"
                    :thumb-color="thumbColor(evaluationPoint.scoring)"
                    :thumb-size="22"
                    @change="
                      updateRecruterClassification(classification, pointIndex)
                    "
                    :readonly="readOnlyClassification"
                  ></v-slider>
                </div>
              </template>
            </div>
            <!-- <div class="mt-5">
              <v-slider
                v-model="classification.points"
                :label="$t('level_of_satisfaction')"
                step="1"
                thumb-label="always"
                :thumb-color="thumbColor(classification.points)"
                :thumb-size="22"
                @change="updateRecruterClassification(classification)"
                :readonly="readOnlyClassification"
              ></v-slider>
            </div> -->
          </v-col>
          <v-col v-if="!isCompleted" cols="12" md="2">
            <div v-if="classification.createdBy === userId">
              <v-btn
                icon
                x-small
                @click="setClassificationToEdit(classification)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                x-small
                @click="setClassificationToDelete(classification)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>
          <div>
            <!-- <pre>{{ getSpecificRatingInQuestion(classification.id) }}</pre> -->

            <v-row class="d-flex flex-column pl-4 mb-4">
              <div class="text-capitalize">{{ $t("evaluator") }}</div>
              <div class="d-flex flex-row">
                <div class="">
                  <span class="my-2" :class="{ 'mx-3': index % 2 === 0 }">
                    <v-chip label color="transparent">
                      <v-avatar>
                        <v-img
                          :src="
                            rantingMemberQuestion.user.photo
                              ? `${apiUrl}/images/user/${rantingMemberQuestion.user.photo}`
                              : `/avatar.png`
                          "
                        ></v-img>
                      </v-avatar>
                      <div class="recruter-name ml-1">
                        <div
                          class="width: 100%"
                          style=" display: inline-flex"
                        >
                          {{ recruterName(rantingMemberQuestion) }}:
                          <!-- </div>
                    <div class="whirelab-results-rating-value"> -->
                          <v-progress-linear
                            :value="
                              getSpecificRatingInQuestion(classification.id)
                            "
                            readonly
                            height="16"
                            rounded
                            :color="
                              thumbColor(
                                getSpecificRatingInQuestion(classification.id)
                              )
                            "
                            class="mt-0 ml-1"
                            style="width: 100px;"
                          >
                            <span class="black--text"
                              >{{
                                getSpecificRatingInQuestion(classification.id)
                              }}%</span
                            >
                          </v-progress-linear>
                        </div>
                      </div>
                    </v-chip>
                    <!-- <pre> {{ ratingMember(recruter) }}</pre> -->
                  </span>
                </div>
              </div>
              <v-btn text class="text-capitalize"
              @click="
                  noteDialog = true;
                  idclass = classification.id;
                "
                ><v-icon color="primary"
                  >mdi-comment-text-multiple-outline</v-icon
                >
                {{ $t("obs") }}({{ classification.notes.length }})</v-btn
              >
            </v-row>
          </div>
        </v-row>
      </template>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              @click="openList()"
              ><v-icon>mdi-playlist-plus</v-icon></v-btn
            >
          </template>
          <span>{{ $t("save_script") }}</span>
        </v-tooltip>
      </div>
      <div>
        <v-row class="d-flex flex-column pl-4 mt-5">
          <div class="">{{ $t("overall_rating_of_the_evaluators") }}</div>
          <div class="d-flex flex-row">
            <div class="">
              <span
                v-for="(recruter, index) in recruters"
                :key="recruter.user.id"
                :content="recruter.comments.length"
                :value="recruter.comments.length"
                bordered
                color="red"
                overlap
                class="my-2"
                :class="{ 'mx-3': index % 2 === 0 }"
              >
                <v-chip label color="transparent">
                  <v-avatar>
                    <v-img
                      :src="
                        recruter.user.photo
                          ? `${apiUrl}/images/user/${recruter.user.photo}`
                          : `/avatar.png`
                      "
                    ></v-img>
                  </v-avatar>
                  <div class="recruter-name ml-1">
                    <div
                      class="width: 100%"
                      style="width: 100px; display: inline-flex"
                    >
                      {{ recruterName(recruter) }}:
                      <!-- </div>
                    <div class="whirelab-results-rating-value"> -->
                      <v-progress-linear
                        :value="ratingMember(recruter)"
                        readonly
                        height="16"
                        rounded
                        :color="thumbColor(ratingMember(recruter))"
                        class="mt-0 ml-1"
                      >
                        <span class="black--text"
                          >{{ ratingMember(recruter) }}%</span
                        >
                      </v-progress-linear>
                    </div>
                  </div>
                </v-chip>
                <!-- <pre> {{ ratingMember(recruter) }}</pre> -->
              </span>
              <span
                v-for="(observer, index) in observers"
                :key="observer.user.id"
                :content="observer.comments.length"
                :value="observer.comments.length"
                bordered
                color="red"
                overlap
                class="my-2"
                :class="{ 'mx-3': index % 2 === 0 }"
              >
                <v-chip label color="transparent">
                  <v-avatar>
                    <v-img
                      :src="
                        observer.user.photo
                          ? `${apiUrl}/images/user/${observer.user.photo}`
                          : `/avatar.png`
                      "
                    ></v-img>
                  </v-avatar>
                  <div
                    class="width: 100%"
                    style="width: 100px; display: inline-flex"
                  >
                    {{ recruterName(observer) }}:
                    <!-- </div>
                    <div class="whirelab-results-rating-value"> -->
                    <v-progress-linear
                      :value="ratingMember(observer)"
                      readonly
                      height="16"
                      rounded
                      :color="thumbColor(ratingMember(observer))"
                      class="mt-0 ml-1"
                    >
                      <span class="black--text"
                        >{{ ratingMember(observer) }}%</span
                      >
                    </v-progress-linear>
                  </div>
                </v-chip>
                <!-- <pre>{{ observer }}</pre> -->
              </span>
            </div>
          </div>
        </v-row>
      </div>
    </div>
    <notes
      :dialog="noteDialog"
      :interviewId="interviewId"
      :selectedCandidate="selectedCandidate"
      :classification="classificationNote"
      :selectedStage="selectedStage"
      :memberInterview="memberInterview"
      :disabled="selectedRecruter.user.id != currentUser.id"
      @close="noteDialog = false"
    />
    <!-- <pre>{{ recruters }}</pre> -->
  </div>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
import Notes from "../Notes.vue";
export default {
  name: "Classification",
  components: {
    Notes,
  },
  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    selectedCandidate: { type: Object },
    selectedRecruter: { type: Object },
    selectedStage: { type: String },
  },

  data: () => ({
    apiUrl: API_URL,
    value: 0,
    classification: {
      designation: "",
      points: 0,
      percentageWeight: null,
      createdBy: null,
    },
    noteDialog:false,
    idclass:undefined,
    member:undefined,
  }),

  created() {
    this.member = {
      ...this.selectedRecruter.user,
      isObserver: this.selectedRecruter.isObserver,
    };
  },
  mounted() {
    this.member = {
      ...this.selectedRecruter.user,
      isObserver: this.selectedRecruter.isObserver,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    classificationNote() {
      return this.selectedRecruter.classification.find(
        (cl) => cl.id === this.idclass
      );
    },
    memberInterview() {
      return {
        evaluatorType: this.member.isObserver ? "observer" : "recruter",
        evaluatorID: this.currentUser
          ? this.member.id === this.currentUser.id
            ? this.member.id
            : undefined
          : undefined,
      };
    },
    userId() {
      return this.$root.$data.userId;
    },
    rantingMemberQuestion() {
      return {
        user: this.selectedRecruter.user,
        rating: this.selectedRecruter.classification,
      };
    },
    recruters() {
      return this.selectedCandidate.stages.find(
        (st) => st.name === this.selectedStage
      ).recruters;
    },
    observers() {
      return this.selectedCandidate.stages.find(
        (st) => st.name === this.selectedStage
      ).observers;
    },
  },

  methods: {
    recruterName(recruterOrObserver) {
      return recruterOrObserver.user.id === this.userId
        ? this.$t("me")
        : this.recruterSplitName(recruterOrObserver.user.name);
    },
    recruterSplitName(name) {
      return name.split(" ")[0];
    },
    ratingMember(recruter) {
      let total = 0;
      let sum = 0;
      recruter.classification.forEach((cls) => {
        sum += parseFloat(cls.points);
        total += 1;
      });
      if (total > 0) {
        return (sum / total).toFixed(2);
      } else {
        return sum.toFixed(2);
      }
    },
    getSpecificRatingInQuestion(id) {
      let rating = this.rantingMemberQuestion.rating.find((rt) => rt.id === id);
      return parseFloat(rating.points).toFixed(2);
    },
    thumbColor(points) {
      if (points < 40) return "red";
      if (points < 50) return "orange";
      if (points < 75) return "indigo";
      if (points < 100) return "teal";
      return "green";
    },
  },
};
</script>
